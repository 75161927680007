const isDev: boolean = process.env.NODE_ENV === 'development';

export const apiBase: string = isDev
  ? 'http://192.168.100.40:3500'
  : 'https://speed.cg-bs.de';

export const DEFAULT_ITEM_COUNT = 25

export enum API_SORT {
  latest = 'latest',
  oldest = 'oldest',
}
