import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chart } from 'primereact/chart';
import { getSpeedResults } from '../api/Results';
import { API_SORT } from '../evn';
import { SpeedResult } from '../models';

// interface ResultItem {
//   id: number;
//   download: number;
//   upload: number;
//   ping: number;
//   server_name: string;
//   server_location: string;
//   runat: string;
// }

function ResultView() {
  const [data, setData] = useState([] as SpeedResult[]);
  const [chartData, setChartData] = useState();
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    getSpeedResults(API_SORT.latest).then((results) => {
      const res = results as SpeedResult[];
      const reverseRes = res.reverse();
      setData(reverseRes);

      const avgSpeed: SpeedResult = reverseRes.reduce(
        (
          avg: SpeedResult,
          current: SpeedResult,
          _: number,
          { length }
        ): SpeedResult => {
          return {
            download:
              _ === 1
                ? avg.download / length + current.download / length
                : avg.download + current.download / length,
            upload:
              _ === 1
                ? avg.upload / length + current.upload / length
                : avg.upload + current.upload / length,
            ping:
              _ === 1
                ? avg.ping / length + current.ping / length
                : avg.ping + current.ping / length,
          };
        }
      );

      const currDateTime = new Date();

      const tmpChartData: any = {
        labels: reverseRes.map((dataEl: SpeedResult) => {
          const tmpDateTime = new Date(dataEl.runat ?? '');
          const dateDiff = tmpDateTime.getTime() - currDateTime.getTime();

          const formatter = new Intl.RelativeTimeFormat('de', {
            numeric: 'auto',
          });

          return [
            formatter.format(Math.round(dateDiff / 86400000), 'day'),
            `${tmpDateTime.getHours()}:${tmpDateTime.getMinutes()}`,
          ];
        }),
        datasets: [
          {
            label: 'Download (Mbps)',
            data: reverseRes.map((dataEl) => dataEl.download / 1_000_000),
            backgroundColor: documentStyle.getPropertyValue('--primary-500'),
            borderColor: documentStyle.getPropertyValue('--primary-600'),
            borderWidth: 3,
            tension: 0.3,
            yAxisID: 'y',
          },
          {
            label: 'Upload (Mbps)',
            data: reverseRes.map((dataEl) => dataEl.upload / 1_000_000),
            backgroundColor: documentStyle.getPropertyValue('--orange-500'),
            borderColor: documentStyle.getPropertyValue('--orange-600'),
            borderWidth: 3,
            tension: 0.3,
            yAxisID: 'y',
          },
          {
            label: 'Ping (ms)',
            type: 'bar',
            data: reverseRes.map((dataEl) => dataEl.ping - avgSpeed.ping),
            backgroundColor: documentStyle.getPropertyValue('--purple-100'),
            borderColor: documentStyle.getPropertyValue('--purple-300'),
            borderWidth: 1,
            fill: true,
            tension: 0.4,
            yAxisID: 'y1',
          },
        ],
      };
      const options: any = {
        plugins: {
          subtitle: {
            display: true,
            text: `Durchschnitt Upload: ${(avgSpeed.upload / 1_000_000).toFixed(
              2
            )} - Download ${(avgSpeed.download / 1_000_000).toFixed(
              2
            )} - Ping: ${avgSpeed.ping}`,
          },
        },
        scales: {
          y: {
            type: 'linear',
            display: true,
            beginAtZero: true,
            position: 'left',
            // ticks: {
            //     color: textColorSecondary
            // },
            // grid: {
            //     color: surfaceBorder
            // }
          },
          y1: {
            type: 'linear',
            display: true,
            beginAtZero: true,
            position: 'right',
            suggestedMax: 50,

            // ticks: {
            //     color: textColorSecondary
            // },
            // grid: {
            //     drawOnChartArea: false,
            //     color: surfaceBorder
            // }
          },
        },
      };

      setChartData(tmpChartData);
      setChartOptions(options);
    });
  }, []);

  const downBodyTmpl = (elem: SpeedResult) => {
    return <div>{(elem.download / 1_000_000).toFixed(2)}</div>;
  };

  const upBodyTmpl = (elem: SpeedResult) => {
    return <div>{(elem.upload / 1_000_000).toFixed(2)}</div>;
  };

  const pingBodyTmpl = (elem: SpeedResult) => {
    return <div>{elem.ping.toFixed(2)}</div>;
  };

  const locationBodyTmp = (elem: SpeedResult) => {
    return (
      <div>
        {elem.server_location
          ? elem.server_location.replace(', Germany', '')
          : 'keine Infos'}
      </div>
    );
  };

  return (
    <div className="mb-4">
      <TabView>
        <TabPanel header="Grapische Auswertung" leftIcon="pi pi-chart-bar mr-2">
          <Chart type="line" data={chartData} options={chartOptions} />
        </TabPanel>
        <TabPanel header="Tabellenansicht" leftIcon="pi pi-table mr-2">
          <DataTable
            value={data}
            size="large"
            showGridlines
            tableStyle={{ minWidth: '50rem' }}
          >
            <Column
              field="download"
              header="Download (Mbps)"
              body={downBodyTmpl}
              align="right"
            />
            <Column
              field="upload"
              header="Upload (Mbps)"
              body={upBodyTmpl}
              align="right"
            />
            <Column
              field="ping"
              header="Ping (ms)"
              body={pingBodyTmpl}
              align="right"
            />
            <Column field="server_name" header="Server Name" />
            <Column
              field="server_location"
              header="Location"
              body={locationBodyTmp}
              align="right"
            />
            <Column field="runat" header="Timestamp" align="right" />
          </DataTable>
        </TabPanel>
      </TabView>
    </div>
  );
}

export default ResultView;
