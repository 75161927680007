import { apiBase, API_SORT, DEFAULT_ITEM_COUNT } from '../evn';
import { SpeedResult } from '../models';

export async function getSpeedResults(sort: API_SORT, limit = DEFAULT_ITEM_COUNT): Promise<SpeedResult[]> {
  return new Promise((resolve, reject) => {
    fetch(`${apiBase}/results/${sort}/${limit}`, { method: 'GET' })
      .then((response) => response.json())
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
}
