import 'primereact/resources/themes/md-light-deeppurple/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import './App.css';
import ResultView from './components/ResultView';

function App() {
  return (
    <div className="text-center m-2">
      <ResultView />
    </div>
  );
}

export default App;
